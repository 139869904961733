import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { userId2NameFunc } from 'utils/formatting';
import { useCurrentUserUsers } from 'utils/hooks/data';
import { formatFunctionDate } from 'utils/tables/format-functions';
import UserInvitation from 'utils/types/UserInvitation';

export const UserInvitationsTable: React.FC<{
  userInvitations: UserInvitation[];
}> = ({ userInvitations }) => {
  const { t } = useTranslation('components');

  const { users } = useCurrentUserUsers();

  const columns = useMemo(() => {
    const userId2Name = userId2NameFunc(users);

    const columnHelper = createColumnHelper<UserInvitation>();

    return [
      columnHelper.accessor('email', {
        id: 'email',
        header: () => t('tables.UserInvitationsTable.columns.email.text'),
        enableColumnFilter: false,
        cell: ({ getValue, row: { original } }) => (
          <span className="font-semibold font-serif">{getValue()}</span>
        ),
      }),

      columnHelper.accessor('invited_by_user_id', {
        id: 'invited_by_user_id',
        header: () => t('tables.UserInvitationsTable.columns.invited_by_user_id.text'),
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return '';
          const name = userId2Name[value];
          const text = name || value;
          return (
            <TableLink
              name={text}
              url={`/user/organization/users/${value}`}
              datatip={t('tables.UserInvitationsTable.columns.invited_by_user_id.dataTip.1')}
              externalDatatip={t(
                'tables.UserInvitationsTable.columns.invited_by_user_id.dataTip.2',
              )}
            />
          );
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.UserInvitationsTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),

      columnHelper.accessor('redeemed_date', {
        id: 'redeemed_date',
        header: () => t('tables.UserInvitationsTable.columns.redeemed_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),
    ];
  }, [t, users]);

  return (
    <Table tableIdentifier="user-invitations-table" data={userInvitations} columns={columns} />
  );
};
