import { faThermometerHalf, faTree, faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'components';
import SensorLink from 'components/SensorLink';
import { lg } from 'utils/breakpoints';
import { useTimePeriod, useWindowSize } from 'utils/hooks';
import SensorStats from 'utils/types/SensorStats';

interface StatsTableData {
  sensor: string;
  id: string;
  tempMin?: number;
  tempMean?: number;
  tempMax?: number;
  humidityMin?: number;
  humidityMean?: number;
  humidityMax?: number;
  moistureMin?: number;
  moistureMean?: number;
  moistureMax?: number;
}

export type StatsTableProps = {
  stats: SensorStats[];
  showSensors?: boolean;
  fromSensorGroupId?: string;
};

export const StatsTable: React.FC<StatsTableProps> = ({
  stats,
  showSensors = false,
  fromSensorGroupId,
}) => {
  const { t } = useTranslation('components');

  const [width] = useWindowSize();
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const statsTableData = useMemo(
    () =>
      stats.map(stat => ({
        sensor: stat.sensor.name,
        id: stat.sensor.id,
        tempMin: stat.temperature?.min,
        tempMean: stat.temperature?.mean,
        tempMax: stat.temperature?.max,
        humidityMin: stat.humidity?.min,
        humidityMean: stat.humidity?.mean,
        humidityMax: stat.humidity?.max,
        moistureMin: stat.moisture?.min,
        moistureMean: stat.moisture?.mean,
        moistureMax: stat.moisture?.max,
      })),
    [stats],
  ) as StatsTableData[];

  const columns = useMemo(() => {
    const formatVal = (value?: number) => value?.toFixed(1) || 'n/a';

    const temperatureValid = statsTableData.some(
      data => data.tempMin || data.tempMean || data.tempMax,
    );

    const humidityValid = statsTableData.some(
      data => data.humidityMin || data.humidityMean || data.humidityMax,
    );

    const moistureValid = statsTableData.some(
      data => data.moistureMin || data.moistureMean || data.moistureMax,
    );

    const columnHelper = createColumnHelper<StatsTableData>();

    return [
      columnHelper.group({
        id: 'sensor_group',
        header: 'Sensor',
        columns: [
          columnHelper.accessor('sensor', {
            id: 'sensor',
            header: '',
            size: width > lg ? 125 : 105,
            enableSorting: false,
            enableColumnFilter: false,
            meta: {
              hidden: !showSensors,
            },
            cell: ({
              getValue,
              cell: {
                row: { original },
              },
            }) => (
              <SensorLink
                sensorId={original.id}
                fromSensorGroupId={fromSensorGroupId}
                timeFrom={timeFrom}
                timeTo={timeTo}
              >
                {getValue()}
              </SensorLink>
            ),
          }),
        ],
      }),

      columnHelper.group({
        id: 'temperature_group',
        header: () => (
          <div className="flex justify-center items-center gap-x-1.5">
            <>
              {t('tables.statsTable.columns.temperature')}{' '}
              <FontAwesomeIcon className="h-5 w-auto" icon={faThermometerHalf} />
            </>
          </div>
        ),
        columns: [
          columnHelper.accessor('tempMin', {
            id: 'temp_min',
            header: () => t('tables.statsTable.columns.tempMin.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !temperatureValid,
            },
            cell: ({ getValue }) => (
              <div className="font-normal text-brand-gray-light-2">{formatVal(getValue())}</div>
            ),
          }),
          columnHelper.accessor('tempMean', {
            id: 'temp_mean',
            header: () => t('tables.statsTable.columns.tempMean.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !temperatureValid,
            },
            cell: ({ getValue }) => <div className="font-bold">{formatVal(getValue())}</div>,
          }),
          columnHelper.accessor('tempMax', {
            id: 'temp_max',
            header: () => t('tables.statsTable.columns.tempMax.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !temperatureValid,
            },
            cell: ({ getValue }) => (
              <div className="font-normal text-brand-gray-light-2">{formatVal(getValue())}</div>
            ),
          }),
        ],
      }),

      columnHelper.group({
        id: 'humidity_group',
        header: () => (
          <div className="flex justify-center items-center gap-x-1.5">
            <>
              {t('tables.statsTable.columns.humidity')}{' '}
              <FontAwesomeIcon className="h-5 w-auto" icon={faWind} />
            </>
          </div>
        ),
        columns: [
          columnHelper.accessor('humidityMin', {
            id: 'humidity_min',
            header: () => t('tables.statsTable.columns.humidityMin.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !humidityValid,
            },
            cell: ({ getValue }) => (
              <div className="font-normal text-brand-gray-light-2">{formatVal(getValue())}</div>
            ),
          }),
          columnHelper.accessor('humidityMean', {
            id: 'humidity_mean',
            header: () => t('tables.statsTable.columns.humidityMean.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !humidityValid,
            },
            cell: ({ getValue }) => <div className="font-bold">{formatVal(getValue())}</div>,
          }),
          columnHelper.accessor('humidityMax', {
            id: 'humidity_max',
            header: () => t('tables.statsTable.columns.humidityMax.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !humidityValid,
            },
            cell: ({ getValue }) => (
              <div className="font-normal text-brand-gray-light-2">{formatVal(getValue())}</div>
            ),
          }),
        ],
      }),

      columnHelper.group({
        id: 'moisture_group',
        header: () => (
          <div className="flex justify-center items-center gap-x-1.5">
            <>
              {t('tables.statsTable.columns.moisture')}
              <FontAwesomeIcon className="h-5 w-auto" icon={faTree} />
            </>
          </div>
        ),
        columns: [
          columnHelper.accessor('moistureMin', {
            id: 'moisture_min',
            header: () => t('tables.statsTable.columns.moistureMin.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !moistureValid,
            },
            cell: ({ getValue }) => (
              <div className="font-bold text-brand-gray-light-2">{formatVal(getValue())}</div>
            ),
          }),
          columnHelper.accessor('moistureMean', {
            id: 'moisture_mean',
            header: () => t('tables.statsTable.columns.moistureMean.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !moistureValid,
            },
            cell: ({ getValue }) => <>{formatVal(getValue())}</>,
          }),
          columnHelper.accessor('moistureMax', {
            id: 'moisture_max',
            header: () => t('tables.statsTable.columns.moistureMax.text'),
            size: 60,
            enableSorting: statsTableData.length >= 2,
            enableColumnFilter: false,
            meta: {
              hidden: !moistureValid,
            },
            cell: ({ getValue }) => (
              <div className="font-normal text-brand-gray-light-2">{formatVal(getValue())}</div>
            ),
          }),
        ],
      }),
    ];
  }, [fromSensorGroupId, showSensors, statsTableData, t, timeFrom, timeTo, width]);

  return (
    <Table tableIdentifier="stats-table" data={statsTableData} columns={columns} pageSize={5} />
  );
};
