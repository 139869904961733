import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink, TableProps } from 'components';
import { useCurrentUserCustomers } from 'utils/hooks/data';
import { customerId2CustomerFunc } from 'utils/mapping';
import { formatFunctionDate } from 'utils/tables/format-functions';
import { Blueprint } from 'utils/types';

export const BlueprintsTable: React.FC<
  {
    blueprints: Blueprint[];
    onClick?: (blueprint: Blueprint) => void;
  } & Omit<TableProps<Blueprint>, 'columns' | 'tableIdentifier' | 'data'>
> = ({ blueprints, onClick, ...props }) => {
  const { customers } = useCurrentUserCustomers();

  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const customerId2Customer = customerId2CustomerFunc(customers);

    const columnHelper = createColumnHelper<Blueprint>();

    return [
      columnHelper.accessor('descriptionTruncated', {
        id: 'description_truncated',
        header: () => t('tables.BlueprintsTable.columns.descriptionTruncated.text'),
      }),

      columnHelper.accessor('customer_id', {
        id: 'customer_id',
        header: () => t('tables.BlueprintsTable.columns.customer_id.text'),
        enableColumnFilter: false,
        size: 250,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return '';
          const customer = customerId2Customer[value];
          return (
            <TableLink
              name={customer ? customer.name : value}
              url={`/admin/customers/${value}`}
              datatip={t('tables.SensorsTable.columns.customer_id.dataTip.1')}
              externalDatatip={t('tables.SensorsTable.columns.customer_id.dataTip.2')}
            />
          );
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.BlueprintsTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        size: 100,
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),
    ];
  }, [customers, t]);

  return (
    <Table
      tableIdentifier="blueprints-table"
      data={blueprints}
      columns={columns}
      showSearchBar={true}
      placeholder={t('tables.BlueprintsTable.search.placeholder')}
      pageSize={5}
      onClick={onClick}
      noDataPlaceholder={t('tables.BlueprintsTable.noDataIndication')}
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
      {...props}
    />
  );
};
