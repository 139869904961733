import { faCompass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInDays } from 'date-fns/differenceInDays';
import {
  BrushOption,
  XAXisOption,
  YAXisOption,
  MarkAreaOption,
  LegendOption,
  LineSeriesOption,
  CustomSeriesOption,
  ScatterSeriesOption,
  BarSeriesOption,
  TooltipOption,
} from 'echarts/types/dist/shared';
import max from 'lodash/max';
import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BasePlot from 'components/plots/helpers/BasePlot';
import { lg, md } from 'utils/breakpoints';
import { brandLime, windSpeed2color, colorMapWinter, brandGreen, brandGray } from 'utils/colors';
import { useWindowSize, useOnMarkTimePeriod, useTimePeriod, usePlots } from 'utils/hooks';
import {
  setStorageBoolean,
  getStorageBoolean,
  STORAGE_PLOTS_DATA_SHOW_ANOMALIES,
} from 'utils/local-storage';
import { getTimeAxisTicks, xAxisFormatter } from 'utils/plots/axis-formatters';
import { axisText } from 'utils/plots/axis-texts';
import createOnBrushSelectedFn, { brushOptions } from 'utils/plots/brush-select';
import { legendFormatter, legendTooltip } from 'utils/plots/legends';
import { lineStyle, grid, gridMobile, itemStyleAnomalies } from 'utils/plots/plot-config';
import toolboxSettings from 'utils/plots/toolbox';
import { tooltipFormatterSingleSensor } from 'utils/plots/tooltip-texts';
import { calculateMax, calculateMin } from 'utils/plots/yAxisMaxAndMin';
import {
  DataTuple,
  DataAnomalyTuple,
  WeatherPrecipitationDataTuple,
  WeatherWindDataTuple,
  DataField,
  LegendValue,
} from 'utils/types/PlotTypes';
import Transmission from 'utils/types/Transmission';
import TransmissionForecast from 'utils/types/TransmissionForecast';
import { groupAndMeanWeatherDataByDate } from 'utils/weather';

const invalidPointSymbolSize = 10;
const defaultMaxMoistureValue = 20;

const MoistureAndPrecipPlot: React.FC<{
  transmissions?: Transmission[];
  transmissionForecasts?: TransmissionForecast[];
  weatherDataPrecip?: WeatherPrecipitationDataTuple[];
  weatherDataWind?: WeatherWindDataTuple[];
  emc?: number[];
  referenceValues?: number[];
  showToolbar?: boolean;
  showAnimation?: boolean;
  enableZoom?: boolean;
  fixMoistureYAxis?: boolean;
  rotateXAxisLabelsForLargeDateRange?: boolean;
}> = ({
  transmissions = [],
  transmissionForecasts,
  weatherDataPrecip = [],
  weatherDataWind = [],
  emc,
  referenceValues,
  showToolbar = true,
  showAnimation = true,
  enableZoom = true,
  fixMoistureYAxis = false,
  rotateXAxisLabelsForLargeDateRange = false,
}) => {
  const [showWind, setShowWind] = useState(false);

  const { t } = useTranslation('components');

  const weatherDataWindGroupedMeaned = groupAndMeanWeatherDataByDate(weatherDataWind);

  const [screenWidth] = useWindowSize();
  const { onMarkTimePeriod, onResetMarkedTimePeriod } = useOnMarkTimePeriod();
  const {
    timePeriod: [timeFrom, timeTo],
    highlightPeriod: [highlightFrom, highlightTo],
  } = useTimePeriod();
  const {
    onTimePeriodSelectClick,
    onTransmissionClick,
    enableAnomalies,
    showForecasts,
    setShowForecasts,
    setShowSignalStrength,
    setShowGateways,
    onMarkAnomaliesClick,
    setShowAnomalyFilteringModal,
    filterAnomalyValidationType,
  } = usePlots();

  const fontSize = screenWidth > lg ? 12 : 10;
  const timeAxisTicks = getTimeAxisTicks(screenWidth);

  // Define x-axis interval
  const minDate = new Date(timeFrom);
  const maxForecastsTime = max(transmissionForecasts?.map(x => x.timestamp));
  const maxDate = maxForecastsTime ? new Date(maxForecastsTime) : new Date(timeTo);
  maxDate.setMinutes(maxDate.getMinutes() + 55);

  const daysAmount = differenceInDays(maxDate, minDate);

  // Define data series
  const dataMoisture = useMemo(
    () =>
      transmissions
        .map(
          transmission =>
            [transmission.timestamp, transmission.moisture, transmission.id] as DataTuple,
        )
        .filter(([x, y]) => y),
    [transmissions],
  );

  // Extract anomalies
  const anomalyPoints = useMemo(
    () =>
      enableAnomalies
        ? transmissions
            .filter(
              transmission =>
                !!transmission.anomaly &&
                (!filterAnomalyValidationType ||
                  transmission.anomaly.validation === filterAnomalyValidationType),
            )
            .map(
              (transmission: Transmission) =>
                [
                  transmission.timestamp,
                  transmission.moisture,
                  transmission.id,
                  transmission.anomaly,
                ] as DataAnomalyTuple,
            )
            .filter(([x, y]: DataAnomalyTuple) => y)
        : [],
    [transmissions, enableAnomalies, filterAnomalyValidationType],
  );

  // Check for invalid transmissions
  const invalidTransmissions = useMemo(
    () => transmissions.filter(x => x.isInvalid),
    [transmissions],
  );

  // destructuring in order to remove width from linestyle
  const { width: x, ...emcLinestyle } = lineStyle;
  const fontFamily = 'Surt, sans-serif';

  const maxForecastMoisture = calculateMax(transmissionForecasts, 'moisture_low');
  const maxTransmissionsMoisture = calculateMax(transmissions, 'moisture');

  const minForecastMoisture = calculateMin(transmissionForecasts, 'moisture_low');
  const minTransmissionsMoisture = calculateMin(transmissions, 'moisture');

  const minMoisture =
    (Math.max(Math.min(Number(minForecastMoisture), Number(minTransmissionsMoisture))), 0);
  const maxMoisture = Math.min(
    Math.max(
      Number(maxForecastMoisture || defaultMaxMoistureValue),
      Number(maxTransmissionsMoisture || defaultMaxMoistureValue),
    ),
    100,
  );

  const deltaMoisture = maxMoisture - minMoisture;

  const { series, legend } = useMemo(() => {
    const legend = ['moisture', 'precip'] as LegendValue[];
    const series: (
      | LineSeriesOption
      | ScatterSeriesOption
      | BarSeriesOption
      | CustomSeriesOption
    )[] = [
      {
        name: 'moisture',
        type: 'line',
        symbolSize: 5,
        lineStyle,
        textStyle: {
          fontFamily,
        },
        data: dataMoisture,
        yAxisIndex: 0,
        color: '#4682B4',
        z: 2,
        markArea:
          highlightFrom &&
          ({
            itemStyle: {
              color: brandLime,
            },
            data: [
              [
                {
                  xAxis: highlightFrom,
                },
                {
                  xAxis: highlightTo,
                },
              ],
            ],
          } as MarkAreaOption),
      } as LineSeriesOption,
      {
        name: 'precip',
        type: 'bar',
        symbolSize: 5,
        lineStyle,
        data: weatherDataPrecip,
        yAxisIndex: 1,
        color: '#191970',
        z: 1,
      } as BarSeriesOption,
    ];

    if (weatherDataPrecip.length === 0) {
      series.splice(1, 1);
      legend.splice(1, 1);
    }

    if (transmissionForecasts && transmissionForecasts.length > 0) {
      series.push(
        {
          name: 'moisture_forecast_low',
          type: 'line',
          data: transmissionForecasts?.map(val => [val.timestamp, val.moisture_low]),
          stack: 'confidence-band',
          lineStyle: {
            opacity: 0,
          },
          showSymbol: false,
        },
        {
          name: 'moisture_forecast' as DataField,
          type: 'line',
          data: transmissionForecasts?.map(val => [
            val.timestamp,
            val.moisture_up - val.moisture_low,
          ]),
          stack: 'confidence-band',
          animation: true,
          areaStyle: {
            color: '#a1a1aa',
          },
          lineStyle: {
            opacity: 0,
          },
          color: '#a1a1aa',
          showSymbol: false,
        },
        {
          name: 'moisture_forecast_mean' as DataField,
          animation: false,
          type: 'line',
          data: transmissionForecasts?.map(val => [val.timestamp, val.moisture_hat]),
          lineStyle: {
            color: '#4682B4',
            ...lineStyle,
          },
          color: '#4682B4',
          showSymbol: false,
        },
      );
    }

    if (emc && emc.length > 0) {
      series.push({
        name: 'emc',
        type: 'line',
        yAxisIndex: 0,
        color: brandGray,
        data: transmissions
          .map((t, index) => [t.timestamp, emc[index], t.id] as DataTuple)
          .filter(([x, y]) => y),
        symbol: 'none',
        lineStyle: { width: 2, ...emcLinestyle, type: 'dashed' },
      } as LineSeriesOption);

      legend.push('emc');
    }

    if (referenceValues && referenceValues.length > 0) {
      series.push({
        name: 'reference_values',
        type: 'line',
        yAxisIndex: 0,
        color: brandGreen,
        data: transmissions
          .map((t, index) => [t.timestamp, referenceValues[index], t.id] as DataTuple)
          .filter(([x, y]) => y),
        symbol: 'none',
        lineStyle: { width: 2, ...emcLinestyle, type: 'dashed' },
      } as LineSeriesOption);

      legend.push('reference_values');
    }

    if (weatherDataWindGroupedMeaned.length > 0) {
      series.push({
        name: 'wind',
        type: 'custom',
        color: windSpeed2color(5),
        renderItem: (params, api) => {
          const point = api.coord([api.value(0), maxMoisture - deltaMoisture / 10]);
          const speed = api.value(1) as number;

          // Color arrow based on wind speed
          const color = windSpeed2color(speed);

          // Rotate direction 90 degrees and invert the direction to match e-charts value format.
          const directionRadians = api.value(2) as number;
          const rotation = (3 * Math.PI) / 2 - directionRadians;
          const arrowSize = 16;

          return {
            type: 'path',
            shape: {
              pathData: 'M31 16l-15-15v9h-26v12h26v9z',
              x: -arrowSize / 2,
              y: -arrowSize / 2,
              width: arrowSize,
              height: arrowSize,
            },
            rotation,
            position: point,
            style: {
              linewidth: 0,
              fill: color,
            },
          };
        },
        data: weatherDataWindGroupedMeaned,
        tooltip: {
          trigger: 'item',
          formatter: tooltipFormatterSingleSensor,
        },
        yAxisIndex: 0,
        z: 3,
      } as CustomSeriesOption);
      // @ts-ignore
      legend.push({ name: 'wind', icon: 'path://M31 16l-15-15v9h-26v12h26v9z' });
    }

    if (invalidTransmissions.length > 0) {
      series.push({
        name: 'moisture_invalid',
        type: 'scatter',
        symbolSize: invalidPointSymbolSize,
        itemStyle: {
          borderColor: '#868e96',
          borderWidth: 1,
          color: '#adb5bd',
          opacity: 1.0,
        },
        z: 3,
        data: invalidTransmissions.map(transmission => [
          transmission.timestamp,
          transmission.moisture,
        ]),
        yAxisIndex: 0,
      } as ScatterSeriesOption);
    }

    // Anomalies
    if (anomalyPoints.length > 0) {
      series.push({
        name: 'anomalies',
        type: 'scatter',
        symbolSize: 7,
        itemStyle: itemStyleAnomalies,
        z: 3,
        data: anomalyPoints,
        yAxisIndex: 0,
      } as ScatterSeriesOption);

      legend.push('anomalies');
    }

    return { series, legend };
  }, [
    dataMoisture,
    transmissions,
    weatherDataPrecip,
    weatherDataWindGroupedMeaned,
    anomalyPoints,
    invalidTransmissions,
    emc,
    referenceValues,
    emcLinestyle,
    highlightFrom,
    highlightTo,
    transmissionForecasts,
    maxMoisture,
    deltaMoisture,
  ]);

  // Get max precip value
  const yMaxPrecip = useMemo(() => {
    let yMaxPrecip = 1;
    const maxPrecip = Math.max.apply(
      null,
      weatherDataPrecip.map(([date, value]: any[]) => value),
    );

    if (isFinite(maxPrecip)) {
      yMaxPrecip = maxPrecip + 0.1;
      if (yMaxPrecip % 1 !== 0) yMaxPrecip = Number(yMaxPrecip.toFixed(1));
    }

    return yMaxPrecip;
  }, [weatherDataPrecip]);

  const yAxis = [
    {
      type: 'value',
      name: axisText('moisture'),
      min: fixMoistureYAxis ? 0 : minMoisture,
      max: fixMoistureYAxis ? 100 : maxMoisture,
      axisLabel: {
        fontSize,
        fontFamily,
        formatter: '{value}%',
      },
      nameTextStyle: {
        fontSize,
        fontFamily,
        fontWeight: 'bold',
      },
      nameLocation: 'middle',
      nameGap: screenWidth > lg ? 50 : 40,
    },
    {
      type: 'value',
      name: axisText('precip'),
      splitLine: null,
      min: 0,
      max: yMaxPrecip,
      axisLabel: {
        fontSize,
        fontFamily,
        formatter: '{value}',
      },
      nameTextStyle: {
        fontStyle: fontSize,
        fontFamily,
        fontWeight: 'bold',
      },
      nameLocation: 'middle',
      nameGap: 40,
    },
  ] as YAXisOption[];

  const xAxis = {
    type: 'time',
    name: axisText('time'),
    nameGap: rotateXAxisLabelsForLargeDateRange && daysAmount >= 4 ? 50 : 30,
    textStyle: {
      fontFamily,
    },
    min: minDate,
    max: maxDate,
    nameLocation: 'middle',
    nameTextStyle: {
      fontFamily,
      fontWeight: 'bold',
      fontSize,
    },
    axisLabel: {
      fontSize,
      fontFamily,
      hideOverlap: true,
      rotate: rotateXAxisLabelsForLargeDateRange && daysAmount >= 4 ? 65 : 0,
      formatter: (value: string, index: number) =>
        xAxisFormatter(value, index, daysAmount, timeAxisTicks),
    },
  } as XAXisOption;

  const toolbox = toolboxSettings({
    saveAsImageFilename: t('plots.CombinedPlots.MoistureAndPrecipPlot.legend.moisture'),
    hardwareId: transmissions[0]?.hardware_id,
    showForecasts,
    showBrush: true,
    timePeriod: [timeFrom, timeTo],
    highlightPeriod: [highlightFrom, highlightTo],
    onTimePeriodSelectClick,
    onTimePeriodResetClick: onResetMarkedTimePeriod,
    onShowForecastsClick: () => setShowForecasts(previousValue => !previousValue),
    onShowSignalStrengthClick: () => setShowSignalStrength(previousValue => !previousValue),
    onShowGatewaysClick: () => setShowGateways(previousValue => !previousValue),
    onMarkAnomaliesClick,
    onShowAnomalyFilteringClick: () => setShowAnomalyFilteringModal(true),
  });

  // Define brush
  const brushSelected = createOnBrushSelectedFn(onMarkTimePeriod);
  // Define animation
  const animationCheck = useMemo(() => transmissions.length < 100, [transmissions]);

  return (
    <div className="relative">
      {showWind && screenWidth > md && (
        <div className="absolute right-0 top-0 text-brand-gray-light-2 pointer-events-none">
          <div className="absolute flex -left-[90px] top-[7px] text-xs">
            <span className="absolute -top-[16px] left-[26px]">m/s</span>
            <span className="relative -top-[3px] -left-[3px]">0</span>
            {[...colorMapWinter].reverse().map((color, index) => (
              <div key={index} className="w-[3px] h-[10px]" style={{ background: color }}></div>
            ))}
            <span className="relative -top-[3px] -right-[3px]">10</span>
          </div>
          <span className="absolute -top-[14px] left-[5px] text-xs font-extrabold">N</span>
          <span>
            <FontAwesomeIcon icon={faCompass} size="lg" />
          </span>
        </div>
      )}
      {/* <BasePlot option={{ xAxis: xAxiss, yAxis: yAxiss }} /> */}
      <BasePlot
        option={{
          xAxis,
          yAxis,
          tooltip: {
            axisPointer: {
              animation: true,
            },
            formatter: tooltipFormatterSingleSensor,
          } as TooltipOption,
          series,
          grid: screenWidth > lg ? grid : gridMobile,
          animation: showAnimation ? animationCheck : false,
          legend: {
            data: legend,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
              fontFamily,
            },
            selected: {
              emc: false,
              anomalies: getStorageBoolean(STORAGE_PLOTS_DATA_SHOW_ANOMALIES),
              wind: showWind,
              reference_values: false,
            } as Record<DataField, boolean>,
            formatter: legendFormatter,
            tooltip: legendTooltip,
          } as LegendOption,
          ...(enableZoom && { brush: brushOptions as BrushOption }),
          ...(showToolbar && { toolbox }),
        }}
        onEvents={{
          click: useCallback(
            ({ value }: { value?: DataTuple }) => {
              if (!value) return;
              const transmissionId = value[2];
              if (onTransmissionClick) onTransmissionClick(transmissionId);
            },
            [onTransmissionClick],
          ),
          legendselectchanged: useCallback(
            (
              {
                name,
                selected,
              }: {
                name: 'moisture' | 'precip' | 'emc' | 'reference_values' | 'wind';
                selected: any;
              },
              chart: any,
            ) => {
              // Save legend selection in local storage
              setStorageBoolean(STORAGE_PLOTS_DATA_SHOW_ANOMALIES, selected.anomalies);

              if (name === 'wind') {
                setShowWind(selected[name]);
              }

              const show = selected[name];
              if (name === 'moisture') {
                // Hide invalid points if any exists
                const invalidSeries = series.find(
                  x => x.name === 'moisture_invalid',
                ) as ScatterSeriesOption;
                if (invalidSeries) {
                  invalidSeries.symbolSize = show ? invalidPointSymbolSize : 0;
                }
                chart.setOption({
                  series,
                });
              }
            },
            [series],
          ),
          brushSelected: brushSelected!,
        }}
      />
    </div>
  );
};

export default MoistureAndPrecipPlot;
