import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useMemo, useRef } from 'react';

import api from 'utils/api';
import AccessLink, { AccessLinkUpdate, parseAccessLink } from 'utils/types/AccessLink';
import HookOptions from 'utils/types/HookOptions';

const useCustomerAccessLinks = (customerId?: string, { enableGet }: HookOptions = {}) => {
  const queryKey = ['customerAccessLinks', customerId];

  const queryClient = useQueryClient();

  const previousAccessLinksRef = useRef<AccessLink[] | undefined>();

  const { data: fetchedAccessLinks, isPending } = useQuery<AccessLink[]>({
    queryKey,
    queryFn: () => api.get(`/customers/${customerId}/access_links`),
    select: (accessLinks: any[]) => accessLinks.map(parseAccessLink),
    enabled: enableGet && !!customerId,
    refetchInterval: 5000,
  });

  const accessLinks = useMemo(() => {
    if (!isEqual(previousAccessLinksRef.current, fetchedAccessLinks)) {
      previousAccessLinksRef.current = fetchedAccessLinks;
      return fetchedAccessLinks || [];
    }
    return previousAccessLinksRef.current;
  }, [fetchedAccessLinks]);

  const { mutateAsync: updateAccessLinks } = useMutation({
    mutationFn: async (values: AccessLinkUpdate): Promise<AccessLink[]> => {
      const accessLinks = await api.post(`/customers/${customerId}/access_links`, values);
      return accessLinks.map(parseAccessLink);
    },
    onSuccess: async accessLinks => {
      queryClient.setQueryData(queryKey, accessLinks);
      await queryClient.invalidateQueries({
        queryKey: ['permissions'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['customerSensors'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['useUtilsPermissionsSensors'],
      });
    },
  });

  return {
    accessLinks,
    updateAccessLinks,
    isPending,
  };
};

export default useCustomerAccessLinks;
