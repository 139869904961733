import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { userId2NameFunc } from 'utils/formatting';
import { useCurrentUserUsers } from 'utils/hooks/data';
import { formatFunctionDate } from 'utils/tables/format-functions';
import DocumentChange from 'utils/types/DocumentChange';

export const DocumentChangesTable: React.FC<{
  data: DocumentChange[];
  columnsToShow?: (keyof DocumentChange)[];
}> = ({
  data,
  columnsToShow = ['collection', 'document_id', 'field', 'value', 'created_date', 'changed_by'],
}) => {
  const { t } = useTranslation('components');

  const { users } = useCurrentUserUsers();

  const columns = useMemo(() => {
    const userId2Name = userId2NameFunc(users);

    const columnHelper = createColumnHelper<DocumentChange>();

    return [
      columnHelper.accessor('field', {
        id: 'field',
        header: () => t('tables.DocumentChangesTable.columns.field.text'),
        size: 175,
        enableColumnFilter: true,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          hidden: !columnsToShow.includes('field'),
        },
        cell: ({ getValue }) => <>{getValue()}</>,
      }),

      columnHelper.accessor('collection', {
        id: 'collection',
        header: () => t('tables.DocumentChangesTable.columns.collection.text'),
        size: 150,
        enableColumnFilter: true,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          hidden: !columnsToShow.includes('collection'),
        },
        cell: ({ getValue }) => <>{getValue()}</>,
      }),

      columnHelper.accessor('document_id', {
        id: 'document_id',
        header: () => t('tables.DocumentChangesTable.columns.document_id.text'),
        enableColumnFilter: false,
        size: 150,
        meta: {
          hidden: !columnsToShow.includes('document_id'),
        },
        cell: ({ getValue, row: { original } }) => {
          let tooltip, tooltipNewWindow, url;
          const value = getValue();
          if (original.collection === 'sensors') {
            tooltip = t('tables.DocumentChangesTable.columns.document_id.tooltip.1');
            tooltipNewWindow = t('tables.DocumentChangesTable.columns.document_id.tooltipNew.1');
            url = `/user/sensors/${value}`;
          } else if (original.collection === 'sensor_groups') {
            tooltip = t('tables.DocumentChangesTable.columns.document_id.tooltip.2');
            tooltipNewWindow = t('tables.DocumentChangesTable.columns.document_id.tooltipNew.2');
            url = `/user/sensor_groups/${value}`;
          } else if (original.collection === 'gateways') {
            tooltip = t('tables.DocumentChangesTable.columns.document_id.tooltip.3');
            tooltipNewWindow = t('tables.DocumentChangesTable.columns.document_id.tooltipNew.3');
            url = `/user/gateways/${value}`;
          } else if (original.collection === 'customers') {
            tooltip = t('tables.DocumentChangesTable.columns.document_id.tooltip.4');
            tooltipNewWindow = t('tables.DocumentChangesTable.columns.document_id.tooltipNew.4');
            url = `/admin/customers/${value}`;
          } else if (original.collection === 'users') {
            tooltip = t('tables.DocumentChangesTable.columns.document_id.tooltip.5');
            tooltipNewWindow = t('tables.DocumentChangesTable.columns.document_id.tooltipNew.5');
            url = `/user/organization/users/${value}`;
          } else if (original.collection === 'demo_applications') {
            tooltip = t('tables.DocumentChangesTable.columns.document_id.tooltip.6');
            tooltipNewWindow = t('tables.DocumentChangesTable.columns.document_id.tooltipNew.6');
            url = `/admin/demo_applications/${value}`;
          } else {
            return <>{value}</>;
          }

          return (
            <TableLink
              name={value}
              url={url}
              datatip={tooltip}
              externalDatatip={tooltipNewWindow}
            />
          );
        },
      }),

      columnHelper.accessor('value', {
        id: 'value',
        header: () => t('tables.DocumentChangesTable.columns.value.text'),
        size: 150,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('value'),
        },
        cell: ({ getValue }) => <>{getValue() || 'null'}</>,
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.DocumentChangesTable.columns.created_date.text'),
        size: 150,
        sortingFn: 'datetime',
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('created_date'),
        },
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),

      columnHelper.accessor('changed_by', {
        id: 'changed_by',
        header: () => t('tables.DocumentChangesTable.columns.changed_by.text'),
        size: 150,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('changed_by'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <>n/a</>;
          const name = userId2Name[value];
          const text = name || value;
          return (
            <TableLink
              name={text}
              url={`/user/organization/users/${value}`}
              datatip={t('tables.DocumentChangesTable.columns.changed_by.dataTip.1')}
              externalDatatip={t('tables.DocumentChangesTable.columns.changed_by.dataTip.2')}
            />
          );
        },
      }),
    ];
  }, [columnsToShow, t, users]);

  return (
    <Table
      tableIdentifier="document-changes-table"
      data={data}
      columns={columns}
      showSearchBar={true}
      showTogglingFilters={true}
      placeholder={t('tables.DocumentChangesTable.search.placeholder')}
      pageSize={5}
    />
  );
};
