import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessLinksTable, UserInvitationsTable } from 'components';
import LoadingCard from 'components/cards/LoadingCard';
import DismissableAlert from 'components/DismissableAlert';
import InfoText from 'components/InfoText';
import { AccessLinkAddModal } from 'components/modals';
import Role from 'utils/enums/Role';
import {
  useCurrentUserCustomers,
  useCurrentUserUsers,
  useSensorGroupAccessLinks,
  useSensorGroupUserInvitations,
} from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';
import AccessLink from 'utils/types/AccessLink';

export const GroupAccessTable: React.FC<{
  groupId?: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
}> = ({ groupId, setShowModal, showModal }) => {
  const { t } = useTranslation('components');

  const { customers, isPending: isPendingCustomers } = useCurrentUserCustomers();
  const { users, isPending: isPendingUsers } = useCurrentUserUsers();
  const {
    accessLinks,
    updateAccessLinks,
    isPending: isPendingAccessLinks,
  } = useSensorGroupAccessLinks(groupId);
  const {
    userInvitations,
    createUserInvitation,
    isPending: isPendingUserInvitations,
  } = useSensorGroupUserInvitations(groupId);

  const isPending =
    isPendingCustomers || isPendingUsers || isPendingAccessLinks || isPendingUserInvitations;

  // Filter users and customers based on existing accessLinks
  const directAccessLinks = accessLinks?.filter(accessLink => !accessLink.inherited_from_group);
  const userIdsAlreadyAdded = directAccessLinks
    ?.map(accessLink => accessLink.user?.id)
    .filter(id => id);
  const customerIdsAlreadyAdded = directAccessLinks
    ?.map(accessLink => accessLink.customer?.id)
    .filter(id => id);

  const usersFiltered = users?.filter(user => !userIdsAlreadyAdded?.includes(user.id));
  const customersFiltered = customers?.filter(
    customer => !customerIdsAlreadyAdded?.includes(customer.id),
  );

  const userInvitationsMem = useMemo(() => userInvitations || [], [userInvitations]);
  const accessLinksMem = useMemo(() => accessLinks || [], [accessLinks]);

  const handleDelete = useCallback(
    async (accessLink: AccessLink) => {
      await updateAccessLinks({
        user_id: accessLink.user?.id,
        customer_id: accessLink.customer?.id,
      });
    },
    [updateAccessLinks],
  );

  const handleUpdate = useCallback(
    async (accessLink: AccessLink, role: Role) => {
      await updateAccessLinks({
        role,
        user_id: accessLink.user?.id,
        customer_id: accessLink.customer?.id,
      });
    },
    [updateAccessLinks],
  );

  return (
    <>
      {isPending && <LoadingCard count={3} />}
      {!isPending && (
        <>
          <DismissableAlert
            storageKey="group-access-links"
            html={<InfoText text={<>{t('GroupAccessTable.dismissableText')}</>} />}
          />

          <InfoText className="mb-4" text={<>{t('GroupAccessTable.infoText')}</>} />

          <AccessLinksTable
            accessLinks={accessLinksMem}
            isPending={isPendingAccessLinks}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />

          {userInvitationsMem.length > 0 && (
            <div className="mt-10">
              <h3 className="mb-3">
                <FontAwesomeIcon className="mr-3" icon={faPaperPlane} />
                {t('GroupAccessTable.UserInvitationsTable.title')}
              </h3>
              <UserInvitationsTable userInvitations={userInvitationsMem} />
            </div>
          )}

          <AccessLinkAddModal
            show={showModal}
            setShow={setShowModal}
            onSelect={async (role, inviteNew, { customer, user, email }) => {
              if (inviteNew) {
                await createUserInvitation({ email: email!, role });
                notificationSuccess(t('GroupAccessTable.AccessLinkAddModal.successTextInvited'));
                setShowModal(false);
              } else {
                await updateAccessLinks({
                  role,
                  user_id: user?.id,
                  customer_id: customer?.id,
                });
                notificationSuccess(t('GroupAccessTable.AccessLinkAddModal.successTextUpdated'));
                setShowModal(false);
              }
            }}
            customers={customersFiltered}
            users={usersFiltered}
            isPending={isPending}
          />
        </>
      )}
    </>
  );
};
