import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { useCurrentUserCustomers } from 'utils/hooks/data';
import Sensor from 'utils/types/Sensor';

type SensorTableData = Sensor & { customer_name?: string };

export const SensorsTable: React.FC<{
  sensors: Sensor[];
  isPending: boolean;
  onDelete?: (sensor: Sensor) => void;
  onClick?: (sensor: Sensor) => void;
}> = ({ sensors, isPending, onClick, onDelete }) => {
  const { t } = useTranslation('components');

  const { customers } = useCurrentUserCustomers();

  const sensorTableData: SensorTableData[] = useMemo(
    () =>
      sensors
        ? sensors?.map(sensor => ({
            ...sensor,
            customer_name: customers?.find(customer => customer.id === sensor.customer_id)?.name,
          }))
        : [],
    [sensors, customers],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SensorTableData>();

    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('tables.SensorsTable.columns.name.text'),
        size: 325,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <TableLink
            name={getValue() || original.id}
            url={`/user/sensors/${original.id}`}
            datatip={t('tables.SensorsTable.columns.name.dataTip.1')}
            externalDatatip={t('tables.SensorsTable.columns.name.dataTip.2')}
          />
        ),
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor('customer_name', {
        id: 'customer_name',
        header: () => t('tables.SensorsTable.columns.customer_name.text'),
        size: 300,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <TableLink
            name={getValue()}
            url={`/admin/customers/${original.customer_id}`}
            datatip={t('tables.SensorsTable.columns.customer_id.dataTip.1')}
            externalDatatip={t('tables.SensorsTable.columns.customer_id.dataTip.2')}
          />
        ),
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor('hardware_id', {
        id: 'hardware_id',
        header: () => t('tables.SensorsTable.columns.hardware_id.text'),
        size: 175,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor('type', {
        id: 'type',
        header: () => t('tables.SensorsTable.columns.type.text'),
        size: 175,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.SensorsTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        size: 175,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <>
            <span
              data-tooltip-content={dateToLocaleString(getValue())}
              data-tooltip-id="route-tooltip"
            >
              {dateToPrettyDate(getValue())}
            </span>
            {onDelete && (
              <FontAwesomeIcon
                className="text-danger float-end"
                icon={faMinusSquare}
                size="lg"
                data-tooltip-content={t('tables.SensorsTable.columns.created_date.dataTip')}
                data-tooltip-id="route-tooltip"
                onClick={event => {
                  event.stopPropagation();
                  onDelete(original);
                }}
              />
            )}
          </>
        ),
      }),
    ];
  }, [t, onDelete]);

  return (
    <Table
      tableIdentifier="sensors"
      data={sensorTableData}
      columns={columns}
      onClick={onClick}
      showSearchBar
      showTogglingFilters={true}
      loading={isPending}
    />
  );
};
