import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFocus } from 'utils/hooks';
export const SettingsDropdown: React.FC<
  {
    size?: SizeProp;
    onEdit: () => void;
    onDelete: () => void;
  } & Pick<HTMLAttributes<HTMLDivElement>, 'className'>
> = ({ size = 'xs', className, onEdit, onDelete }) => {
  const { t } = useTranslation('components');

  const [dropdownContainerRef, setFocus] = useFocus();
  const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);

  let textClass = '';
  let dropdownTopSpacing = '';
  switch (size) {
    case 'xs': {
      textClass = 'py-1 px-[6px] text-[9px]';
      dropdownTopSpacing = 'top-6';
      break;
    }
    case 'lg': {
      textClass = 'p-2';
      dropdownTopSpacing = 'top-10';
      break;
    }
    default: {
      textClass = 'py-1 px-[6px] text-[9px]';
      dropdownTopSpacing = 'top-6';
      break;
    }
  }

  return (
    <div
      tabIndex={0}
      ref={dropdownContainerRef}
      className={classNames(className, 'rounded-t focus:outline-none z-30')}
      onMouseEnter={() => {
        setFocus();
        setShowOptionsDropdown(true);
      }}
      onMouseLeave={() => {
        setShowOptionsDropdown(false);
      }}
      onBlur={() => {
        setShowOptionsDropdown(false);
      }}
    >
      <div
        className={classNames(
          'rounded-full inline-block leading-none absolute right-0 top-0 bg-brand-blue text-xs',
          'transition-translate duration-300',
          textClass,
          showOptionsDropdown ? 'hover:rotate-[360deg]' : 'hover:bg-brand-blue-light-1',
        )}
        onClick={() => setShowOptionsDropdown(!showOptionsDropdown)}
      >
        <FontAwesomeIcon className="text-white" icon={faGear} size={size} />
      </div>
      <div
        className={classNames(
          'absolute shadow right-0 flex flex-col text-center overflow-hidden rounded transition-[width, height] duration-300 text-brand-gray py-4',
          dropdownTopSpacing,
          showOptionsDropdown
            ? 'h-[6rem] w-24 bg-white border border-brand-gray'
            : 'h-0 w-0 bg-none',
        )}
      >
        <button
          className="grow w-full m-auto hover:bg-brand-blue hover:text-white"
          onMouseDown={() => {
            // use onMouseDown because onClick won't fire before the parent element loses focus, preventing the modal from showing
            onEdit();
          }}
        >
          {t('dropdowns.SettingsDropdown.edit')}
        </button>
        <button
          className="grow w-full m-auto hover:bg-brand-orange hover:text-white"
          onMouseDown={() => {
            onDelete();
          }}
        >
          {t('dropdowns.SettingsDropdown.delete')}
        </button>
      </div>
    </div>
  );
};
