import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { sensorId2NameFunc, userId2NameFunc } from 'utils/formatting';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useCurrentUserUsers, useUserAndCustomerSensors } from 'utils/hooks/data';
import { getAlarmTypeText } from 'utils/sensor/texts';
import { boolean2text } from 'utils/texts/common';
import AlarmNotification from 'utils/types/AlarmNotification';

export const AlarmNotificationsTable: React.FC<{
  data: AlarmNotification[];
  onClick?: (alarmNotification: AlarmNotification) => void;
  columnsToShow?: (keyof AlarmNotification)[];
  hideHeader?: boolean;
  isPending?: boolean;
}> = ({
  data,
  onClick,
  columnsToShow = ['sent_time', 'description', 'type', 'sensor_id', 'seen_on_web'],
  hideHeader,
  isPending,
  ...props
}) => {
  const { t } = useTranslation('components');

  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { users } = useCurrentUserUsers();
  const { sensors, isPending: isPendingSensors } = useUserAndCustomerSensors(userId, customerId);

  const columns = useMemo(() => {
    const userId2Name = userId2NameFunc(users);
    const sensorId2Name = sensorId2NameFunc(sensors);

    const columnHelper = createColumnHelper<AlarmNotification>();

    return [
      columnHelper.accessor('sensor_id', {
        id: 'sensor_id',
        header: () => t('tables.AlarmNotificationsTable.columns.sensor_id.text'),
        enableColumnFilter: false,
        size: 120,
        meta: {
          hidden: !columnsToShow.includes('sensor_id') && !columnsToShow.includes('gateway_id'),
        },
        cell: ({ getValue, row: { original } }) =>
          original.sensor_id ? (
            <TableLink
              name={sensorId2Name[original.sensor_id] || getValue()}
              url={`/user/sensors/${getValue()}`}
              datatip={t('tables.AlarmNotificationsTable.columns.sensor_id.dataTip.1')}
              externalDatatip={t('tables.AlarmNotificationsTable.columns.sensor_id.dataTip.2')}
            />
          ) : (
            <TableLink
              name={original.gateway_id}
              url={`/user/gateways/${original.gateway_id}`}
              datatip={t('tables.AlarmNotificationsTable.columns.gateway_id.dataTip.1')}
              externalDatatip={t('tables.AlarmNotificationsTable.columns.gateway_id.dataTip.2')}
            />
          ),
      }),

      columnHelper.accessor('id', {
        id: 'id',
        header: () => t('tables.AlarmNotificationsTable.columns.id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('id'),
        },
      }),

      columnHelper.accessor('description', {
        id: 'description',
        header: () => t('tables.AlarmNotificationsTable.columns.description.text'),
        enableColumnFilter: false,
        size: 300,
        meta: {
          hidden: !columnsToShow.includes('description'),
        },
      }),

      columnHelper.accessor('sent_time', {
        id: 'sent_time',
        header: () => t('tables.AlarmNotificationsTable.columns.sent_time.text'),
        sortingFn: 'datetime',
        size: 100,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('sent_time'),
        },
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('user_id', {
        id: 'user_id',
        header: () => t('tables.AlarmNotificationsTable.columns.user_id.text'),
        enableColumnFilter: false,
        size: 100,
        meta: {
          hidden: !columnsToShow.includes('user_id'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <>n/a</>;

          const name = userId2Name[value];
          const text = name ?? value;
          return (
            <TableLink
              name={text}
              url={`/user/organization/users/${getValue()}`}
              datatip={t('tables.DocumentChangesTable.columns.changed_by.dataTip.1')}
              externalDatatip={t('tables.DocumentChangesTable.columns.changed_by.dataTip.2')}
            />
          );
        },
      }),

      columnHelper.accessor('type', {
        id: 'type',
        header: () => t('tables.AlarmNotificationsTable.columns.type.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('type'),
        },
        cell: ({ getValue }) => <>{getAlarmTypeText(getValue())}</>,
      }),

      columnHelper.accessor('seen_on_web', {
        id: 'seen_on_web',
        header: () => t('tables.AlarmNotificationsTable.columns.seen_on_web.text'),
        size: 50,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('seen_on_web'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <>
              {boolean2text(value)}
              <FontAwesomeIcon
                className={classNames('ml-2 text-xl', {
                  'text-sprout-lightest': value,
                  'text-crimson': !value,
                })}
                icon={value ? faCheck : faExclamationTriangle}
              />
            </>
          );
        },
      }),

      columnHelper.accessor('resolved_time', {
        id: 'resolved_time',
        header: () => t('tables.AlarmNotificationsTable.columns.resolved_time.text'),
        sortingFn: 'datetime',
        size: 50,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('resolved_time'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) {
            return <>{t('tables.AlarmNotificationsTable.columns.resolved_time.no')}</>;
          }

          return (
            <>
              {t('tables.AlarmNotificationsTable.columns.resolved_time.resolvedText', {
                date: dateToPrettyDate(value),
              })}
            </>
          );
        },
      }),

      columnHelper.accessor('receiver_email', {
        id: 'receiver_email',
        header: () => t('tables.AlarmNotificationsTable.columns.receiver_email.text'),
        size: 100,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('receiver_email'),
        },
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),
    ];
  }, [columnsToShow, sensors, t, users]);

  return (
    <Table
      tableIdentifier="alarm-notifications-table"
      data={data}
      columns={columns}
      pageSize={5}
      onClick={onClick}
      hideHeader={hideHeader}
      noDataPlaceholder={t('tables.AlarmNotificationsTable.noDataIndication')}
      loading={isPending || isPendingSensors}
      {...props}
    />
  );
};
