import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { faWsGroup } from 'components/icons';
import logo from 'images/logo.png';
import { useSensorGroupPrimaryFile } from 'utils/hooks/data';
import SensorGroup from 'utils/types/SensorGroup';

const GroupLinkWithPreview: React.FC<{
  sensorGroup?: SensorGroup;
  isPending?: boolean;
  timeFrom?: Date;
  timeTo?: Date;
  className?: string;
  externalDatatip?: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
  textClassName?: string;
  showIcon?: boolean;
}> = ({
  sensorGroup,
  isPending,
  timeFrom,
  timeTo,
  className,
  externalDatatip,
  place = 'left',
  textClassName = 'mb-1 text-xl',
  showIcon = false,
}) => {
  const { file } = useSensorGroupPrimaryFile(
    sensorGroup?.id,
    false,
    !!sensorGroup?.sensor_group_file_id,
  );

  const to = {
    pathname: `/user/groups/${sensorGroup?.id}/overview`,
    search: queryString.stringify({
      timeFrom: timeFrom?.toISOString(),
      timeTo: timeTo?.toISOString(),
    }),
  };
  const onClick = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

  return (
    <>
      <ReactTooltip
        id={`group-${sensorGroup?.id}`}
        className="z-50"
        aria-haspopup="true"
        place={place}
        data-tooltip-position-strategy="fixed"
      >
        <img src={file?.storage_url ?? logo} alt="full size" className="max-w-xs h-auto" />
      </ReactTooltip>

      <Link
        className={classNames('underline text-brand-green hover:text-blue-500', className)}
        to={to}
        onClick={onClick}
        data-tooltip-id={`group-${sensorGroup?.id}`}
      >
        <h4
          className={classNames(
            'text-brand-green hover:text-blue-500 inline hover:underline',
            textClassName,
          )}
        >
          {showIcon && <FontAwesomeIcon className="mr-2" icon={faWsGroup} />}
          {isPending ? <Skeleton width={250} /> : sensorGroup?.name}
        </h4>
      </Link>

      {externalDatatip && (
        <Link
          className="float-right underline text-brand-green hover:text-blue-500"
          target="_blank"
          onClick={onClick}
          to={to}
          data-tooltip-content={externalDatatip}
          data-tooltip-id="route-tooltip"
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Link>
      )}
    </>
  );
};

export default GroupLinkWithPreview;
