import classNames from 'classnames';
import Konva from 'konva';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Label, Tag, Text, Stage, Layer } from 'react-konva/lib/ReactKonvaCore';
import 'konva/lib/shapes/Text';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Image';

import {
  MAX_SCALE,
  MIN_SCALE,
  PERFECT_DRAW_ENABLED,
  SCALE_STEPS,
} from 'components/CanvasStage/CanvasStage';
import { ImageSize, LabelProps, StageState } from 'components/CanvasStage/types';
import { isMac } from 'utils/browsers';
import environment from 'utils/environment';
import { useIsPressingModKey } from 'utils/hooks';
import { Environment, isFeatureEnabled } from 'utils/isFeatureEnabled';

export type MainStageProps = {
  stageRef: React.MutableRefObject<Konva.Stage> | undefined;
  backgroundImage: HTMLImageElement | undefined;
  stageHeight: number;
  stageState: StageState;
  enableWheelScrolling?: boolean;
  enableDragging?: boolean;
  grayscale: boolean;
  imageSize: ImageSize;
  imageConfigs?: Konva.ImageConfig[];
  labelConfigs?: LabelProps[];
  setStageState: React.Dispatch<React.SetStateAction<StageState>>;
  onClick: (({ evt, target }: Konva.KonvaEventObject<MouseEvent>) => void) | undefined;
  onDragEnd?: ({ target }: Konva.KonvaEventObject<DragEvent>) => void;
  onDragStart?: ({ target }: Konva.KonvaEventObject<DragEvent>) => void;
};

export const MainStage: React.FC<MainStageProps> = ({
  stageRef,
  backgroundImage,
  stageHeight,
  stageState,
  enableWheelScrolling = false,
  enableDragging = false,
  grayscale,
  imageSize,
  imageConfigs,
  labelConfigs,
  setStageState,
  onClick,
  onDragEnd,
  onDragStart,
}) => {
  const backgroundImageRef = useRef<Konva.Image>() as MutableRefObject<Konva.Image> | undefined;
  const showScrollZoomOverlayTimeout = useRef<NodeJS.Timeout>();

  const [showScrollZoomOverlay, setShowScrollZoomOverlay] = useState(false);

  const { t } = useTranslation('components');
  const isPressingModKey = useIsPressingModKey();

  useEffect(() => {
    if (isPressingModKey) {
      clearTimeout(showScrollZoomOverlayTimeout.current);
      setShowScrollZoomOverlay(false);
    }
  }, [isPressingModKey]);

  const triggerScrollZoomOverlay = () => {
    if (!showScrollZoomOverlay) {
      setShowScrollZoomOverlay(true);

      clearTimeout(showScrollZoomOverlayTimeout.current);
      showScrollZoomOverlayTimeout.current = setTimeout(() => {
        setShowScrollZoomOverlay(false);
      }, 2000);
    }
  };

  const handleWheel = ({ evt, target }: Konva.KonvaEventObject<WheelEvent>) => {
    if (!isPressingModKey) {
      triggerScrollZoomOverlay();
      return;
    }
    evt.preventDefault();

    const scaleBy = SCALE_STEPS;
    const stage = target.getStage();
    if (!stage) return;

    const oldScale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;
    const mousePointTo = {
      x: pointerPosition.x / oldScale - stage.x() / oldScale,
      y: pointerPosition.y / oldScale - stage.y() / oldScale,
    };

    const newScale = evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale < MIN_SCALE || MAX_SCALE < newScale) return;

    setStageState({
      scale: newScale,
      x: -(mousePointTo.x - pointerPosition.x / newScale) * newScale,
      y: -(mousePointTo.y - pointerPosition.y / newScale) * newScale,
    });
  };

  // Handle dragging (panning)
  const handleDrag = ({ evt, target }: Konva.KonvaEventObject<DragEvent>) => {
    evt.preventDefault();

    const stage = target.getStage();
    if (!stage) return;

    const scale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;
    const mousePointTo = {
      x: pointerPosition.x / scale - stage.x() / scale,
      y: pointerPosition.y / scale - stage.y() / scale,
    };

    setStageState({
      scale,
      x: -(mousePointTo.x - pointerPosition.x / scale) * scale,
      y: -(mousePointTo.y - pointerPosition.y / scale) * scale,
    });
  };

  // Scale image and label position and sizes
  const scaledImages = constructScaledImages(imageConfigs, stageState, imageSize);
  const scaledLabels = constructScaledLabels(labelConfigs, stageState, imageSize);

  const isToggleBlueprintColorEnabled = isFeatureEnabled(
    'toggleBlueprintColor',
    environment as Environment,
  );

  const imageSizeIsValid = imageSize.height !== 0 && imageSize.width !== 0;

  // Cache background image
  // if (imageSizeIsValid && backgroundImageRef?.current && !backgroundImageRef?.current?.isCached()) {
  //   backgroundImageRef.current.cache({ pixelRatio: 1 });
  // }

  return (
    <div className="relative">
      <div
        className={classNames(
          'w-full absolute top-0 left-0 z-10 flex items-center justify-center pointer-events-none bg-brand-gray',
          {
            'transition ease-in-out duration-1000': !isPressingModKey,
            'bg-opacity-70': showScrollZoomOverlay,
            'bg-opacity-0': !showScrollZoomOverlay,
          },
          {
            hidden: isPressingModKey,
          },
        )}
        style={{ height: stageHeight }}
      >
        <p
          className={classNames('text-xl text-white', {
            'transition ease-in-out duration-1000': !isPressingModKey,
            'opacity-100': showScrollZoomOverlay,
            'opacity-0': !showScrollZoomOverlay,
          })}
        >
          {t('CanvasStage.components.MainStage.holdModKeyForScroll.use')}
          {isMac() ? (
            <span
              className="relative font-bold ml-2 mr-1"
              style={{ fontSize: '1.6rem', top: '1px' }}
            >
              {'\u2318'}
            </span>
          ) : (
            <samp className="font-bold ml-2 mr-1 tracking-tighter">Ctrl</samp>
          )}
          + {t('CanvasStage.components.MainStage.holdModKeyForScroll.scrollToZoom')}
        </p>
      </div>
      <Stage
        height={stageHeight}
        ref={stageRef}
        onWheel={enableWheelScrolling ? handleWheel : undefined}
        onDragEnd={handleDrag}
        onDragMove={onDragEnd}
        onDragStart={onDragStart}
        scaleX={stageState.scale}
        scaleY={stageState.scale}
        x={stageState.x}
        y={stageState.y}
        draggable={enableDragging}
      >
        <Layer>
          <Image
            class
            ref={backgroundImageRef}
            filters={
              isToggleBlueprintColorEnabled && grayscale ? [Konva.Filters.Grayscale] : undefined
            }
            perfectDrawEnabled={PERFECT_DRAW_ENABLED}
            image={backgroundImage}
            onClick={onClick}
          />

          {imageSizeIsValid && scaledImages}
          {imageSizeIsValid && scaledLabels}
        </Layer>
      </Stage>
    </div>
  );
};

const constructScaledImages = (
  imageConfigs: Konva.ImageConfig[] | undefined,
  stageState: StageState,
  imageSize: ImageSize,
) =>
  imageConfigs?.map(({ x, y, width, height, offsetX, offsetY, ...props }, index) => {
    const imageScale =
      props.image instanceof HTMLImageElement ? props.image.height / props.image.width : 1.0;

    const scaledWidth = width! / stageState.scale;
    const scaledHeight = scaledWidth * imageScale;

    return (
      <Image
        key={index}
        x={x! * imageSize.width}
        y={y! * imageSize.height}
        width={scaledWidth}
        height={scaledHeight}
        offsetX={scaledWidth / 2}
        offsetY={scaledHeight / 2}
        perfectDrawEnabled={PERFECT_DRAW_ENABLED}
        {...props}
      />
    );
  });

const constructScaledLabels = (
  labelConfigs: LabelProps[] | undefined,
  stageState: StageState,
  imageSize: ImageSize,
) =>
  labelConfigs?.map(
    (
      {
        x,
        y,
        tag: { pointerWidth, pointerHeight, strokeWidth, ...tagProps },
        text: { fontSize, padding, ...textProps },
        ...labelProps
      },
      index,
    ) => (
      <Label key={index} x={x! * imageSize.width} y={y! * imageSize.height} {...labelProps}>
        <Tag
          pointerWidth={pointerWidth! / stageState.scale}
          pointerHeight={pointerHeight! / stageState.scale}
          strokeWidth={strokeWidth! / stageState.scale}
          {...tagProps}
        />
        <Text
          fontSize={fontSize! / stageState.scale}
          padding={padding! / stageState.scale}
          {...textProps}
        />
      </Label>
    ),
  );
