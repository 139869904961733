import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandButton, Table, TableProps } from 'components';
import { formatFunctionDate, percentageMetricCellFn } from 'utils/tables/format-functions';
import GatewayHeartbeat from 'utils/types/GatewayHeartbeat';

export const GatewayHeartbeatsTable: React.FC<
  {
    data: GatewayHeartbeat[];
    showUser?: boolean;
    onClick?: (gatewayHeartbeat: GatewayHeartbeat) => void;
    expandable?: boolean;
  } & Omit<TableProps<GatewayHeartbeat>, 'columns' | 'data' | 'tableIdentifier'>
> = ({ data, showUser = false, onClick, expandable = false, ...props }) => {
  const [showTable, setShowTable] = useState(!expandable);

  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<GatewayHeartbeat>();

    return [
      columnHelper.accessor('gateway_id', {
        id: 'gateway_id',
        header: () => t('tables.GatewayHeartbeatsTable.columns.gateway_id.text'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor('timestamp', {
        id: 'timestamp',
        header: () => t('tables.GatewayHeartbeatsTable.columns.timestamp.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),

      columnHelper.accessor('meta_cpu_usage', {
        id: 'meta_cpu_usage',
        header: () => t('tables.GatewayHeartbeatsTable.columns.meta_cpu_usage.text'),
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_memory_usage', {
        id: 'meta_memory_usage',
        header: () => t('tables.GatewayHeartbeatsTable.columns.memory.text'),
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_sd_card_usage', {
        id: 'meta_sd_card_usage',
        header: () => t('tables.GatewayHeartbeatsTable.columns.meta_sd_card_size_kb.text'),
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_rootfs_usage', {
        id: 'meta_rootfs_usage',
        header: () => t('tables.GatewayHeartbeatsTable.columns.meta_rootfs_size_kb.text'),
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_modem_access_techonology', {
        id: 'meta_modem_access_techonology',
        header: () => t('tables.GatewayHeartbeatsTable.columns.meta_modem_access_techonology.text'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor('meta_modem_signal_rssi', {
        id: 'meta_modem_signal_rssi',
        header: () => t('tables.GatewayHeartbeatsTable.columns.meta_modem_signal_rssi.text'),
        enableColumnFilter: false,
      }),
    ];
  }, [t]);

  return (
    <>
      <h5 className="mb-1 text-lg font-bold">
        {t('tables.GatewayHeartbeatsTable.title')}

        {expandable && (
          <ExpandButton
            className="ml-2"
            expanded={showTable}
            setExpanded={setShowTable}
            data-tooltip-content={
              showTable
                ? t('tables.GatewayHeartbeatsTable.ExpandButton.hide')
                : t('tables.GatewayHeartbeatsTable.ExpandButton.show')
            }
            data-tooltip-id="route-tooltip"
            data-testid="expand-button-gw-heartbeats"
          />
        )}
      </h5>
      {showTable && (
        <Table
          tableIdentifier="gateway-heartbeats-table"
          data={data}
          columns={columns}
          pageSize={5}
          onClick={(row: GatewayHeartbeat) => {
            if (typeof onClick === 'function') {
              onClick(row);
            }
          }}
          noDataPlaceholder={t('tables.GatewayHeartbeatsTable.noDataIndication')}
          sortBy={[
            {
              id: 'timestamp',
              desc: true,
            },
          ]}
          {...props}
        />
      )}
    </>
  );
};
